<div class="my-4">
    <div class="">
        <div class="card-body">
            <div class="mb-4 mt-4 d-flex justify-content-end px-3">
                        <input
                          class="form-control rounded mx-2"
                          type="text"
                          placeholder="Search..."
                          id="example-search-input"
                          [(ngModel)]="searchWord"
                          style="width:180px;"
                        />
                        <span *ngIf="clearButtonCheck && searchWord.length" style="position: absolute;right: 120px;color: red;cursor: pointer;border-radius: 50%;background: #e8e3e3;" (click)="clearData()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </span>
                        <button type="button" (click)="searchData()" class="btn btn-primary">Search</button>
            </div>
            <div class="row px-4">
              <div  *ngIf="isOpenFolder" class="col-sm-12 row"><i class="col-sm-auto float-lg-start fa fa-arrow-left fa-2x cursor-pointer" (click)="backToParent()"></i> 
                <span class="col-sm-4">{{searchFolder}}</span>
              </div>
                <!-- <div class="">
                    <div class="">
                        <app-paginator [totalCount]="totalCount" [page]="page" [pageSize]="paginatorSize" (nextPage)="pageEvent($event)" ></app-paginator>
                    </div>
                </div> -->
              
            </div>
            <div class="row mb-4 mt-4" *ngIf="cardData && cardData.length;else noData">
              <ng-container *ngIf="!isOpenFolder">
              <div class="card m-2 pt-2 shadow col-sm-2 cursor-pointer" (dblclick)="openFolder(folder)" *ngFor="let folder of document">
                <div class="col-sm-12 text-center">
                <i class="fa fa-folder fa-5x col-sm-12 folder-blue"></i>
                <label>{{folder.label}}</label>
                
              </div>
              </div>
            </ng-container>
              <div class="row" *ngIf="isOpenFolder">
                <div class="col-sm-3" *ngFor="let item of cardData | filter: searchTerm: 'state'" >
                  <div class="card m-2 p-2 shadow" (click)="openDialog(item)">
                    <div style="width: 100%; text-decoration: underline;">{{item?.state}}</div>
                     <div style="width: 100%; ">
                         <img [src]="item.type  == 'pdf' ? '/assets/images/pdf.svg' : item.type  == 'csv' ? '/assets/images/svg.png' : '/assets/images/pdf.svg'"  style="cursor: pointer;height:100px;"  class="card-img-top img-fluid" alt="...">
                     </div>
                    <div class="card-body" >
                      <div class="mb-1 text-truncate" matTooltip="{{item?.title}}">
                        <img [src]="item.type  == 'pdf' ? '/assets/images/pdf.svg' : item.type  == 'csv' ? '/assets/images/svg.png' : '/assets/images/pdf.svg'" alt="" style="width: 20px;">
                        <a style="cursor: pointer;margin-left: 6px;" target="_blank"
                            >{{item?.title}}</a>
                      </div>
                      <!-- <div>
                        <p class="mb-1">{{item?.description}}</p>
                        <small>Published Date: {{item?.publishedDate | date:'mediumDate'}}</small>
                      </div>
                      <div><img src="/assets/images/download.svg" alt="">
                        <small>Total Downloads: <strong>{{getTotalDownload(item.fileList)}}</strong></small>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <ng-template #noData >
                <div class="row mb-4 mt-4" *ngIf="!isProgress">
                  <div style="text-align: center;margin-top: 50px;">
                    <h1>
                      No Data Found
                    </h1>
                  </div>
    
                </div>
              </ng-template>
        </div>
    </div>
</div>