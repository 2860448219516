import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name : 'customPercentPipe'
})

export class CustomPipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        return (value * 100).toFixed(2) + '%';
    }
}