import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as Chart from 'chart.js';
import jsPDF from 'jspdf';
import { ChartService } from 'src/app/shared/services/chart.service';
import { HomeService } from 'src/app/shared/services/home.service';
import html2canvas from 'html2canvas';
import * as JSZip from 'jszip';
import * as saveAs from 'file-saver';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss'],
})
export class AccessibilityComponent implements OnInit {

  id1:string = "barchartId1";
  id2:string = "barchartId2";
  type:string = "bar";
  activeButton:any;
  data:any = {
    labels: ['Score', 'Theme Avg.', 'Destination Avg.', 'State Avg.','National Avg.'],
    datasets: [{
      label:"",
      barThickness: 20,
      data: [4,3,3,2.4,5],
      backgroundColor: [
        "#F7906E",
        "#ffb1c1",
        "#9ad0f5",
        "#F3B6EF",
        "#D1F48F",

      ],
      borderColor:"",
      borderWidth: 1
    }],
  }
  // imageObject = [{
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
  //   title: 'Hummingbirds are amazing creatures'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //   title: 'Example with title.'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //   title: 'Hummingbirds are amazing creatures'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
  //   title: 'Example two with title.'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //   title: 'Example with title.'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //   title: 'Hummingbirds are amazing creatures'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg'
  // }, {
  //   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
  //   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
  //   title: 'Example two with title.'
  // }];
  imageObject:any = [];
  chartRef:any[] = [];
  templeName = 'Golden Temple';

  amenitiesJson:any = {
    type: 'bar',
    data : {
      labels: ['Akshardham Temple', 'Total Visitors', 'Avg. Visitor Per Day', 'Total Security'],
      datasets: [{
        label:"",
        barThickness: 20,
        data: [1.4,2.5,4,2],
        backgroundColor: '#0262af',
        borderColor: '#0262af',
        borderWidth: 1
      }]
    }
  };
  urlData:any ;
  showHistoricalPage: boolean = true;
  selectedTabName: string = "";

 column = ['score','theme','destination','state','national'];
 header = ['accessibility_public','accessibility_price','accessibility_adequate','accessibility_traffic','accessibility_tourist','accessibility_information','accessibility_overall','amenities_tourist','amenities_hotel','amenities_condition','amenities_seating','amenities_overall','hygiene_general','hygiene_restaurant','hygiene_wayside','hygiene_washroom','hygiene_overall','safety_perception','safety_security','safety_tourist','safety_level','safety_overall'];

 cardsHeading:any = {1:{showHide:{'Overall Rating':true,'Public Transport':true,'Price of cab/taxis':true,'Adequate transport facilities':true,'Traffic congestion in the destination':true,'Tourist congestion management':true, 'Information pertaining to the tourist destination':true},title:['Overall Rating','Public Transport','Price of cab/taxis','Adequate transport facilities','Traffic congestion in the destination','Tourist congestion management', 'Information pertaining to the tourist destination'], key:['accessibility_overall','accessibility_public','accessibility_price','accessibility_adequate','accessibility_traffic','accessibility_tourist','accessibility_information']}, 2:{showHide:{'Overall Rating':true,'Tourist Information center':true, 'Hotel/accomodation':true,'Condition of infrastructure':true,'Seating facility':true},title:['Overall Rating','Tourist Information center', 'Hotel/accomodation','Condition of infrastructure','Seating facility'],key:['amenities_overall','amenities_tourist','amenities_hotel','amenities_condition','amenities_seating']}, 3:{showHide:{'Overall Rating':true,'General cleanliness':true, 'Restaurants/eating joints':true,'WaySide restaurants/eating joints':true,'Washrooms':true},title:['Overall Rating','General cleanliness', 'Restaurants/eating joints','WaySide restaurants/eating joints','Washrooms'],key:['hygiene_overall','hygiene_general','hygiene_restaurant','hygiene_wayside','hygiene_washroom']},4:{showHide:{'Overall Rating':true,'Perception on crime':true, 'Security of women':true,'Tourist/general polics':true,'Level of overcrowding':true},title:['Overall Rating','Perception on crime', 'Security of women','Tourist/general polics','Level of overcrowding'],key:['safety_overall','safety_perception','safety_security','safety_tourist','safety_level']}};

 tabList=['','Accessibility', 'Amenities','Hygiene','Safety & Security'];
  siteDetails: any;
  photosKey:any = ['','accessibility_photos','amenities_photos','hygiene_photos','safety_photos']
  siteAbout: string = "";
  scoreCardObj:any = {};
  siteName: string = '';
  allImageData: any = [];
  chartName: any;
  imageClick : boolean = false;
  constructor(
    private chartService:ChartService, private router: Router,private route: ActivatedRoute, private homeService: HomeService,
    private _snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
    let id = sessionStorage.getItem('id');
    let siteName = sessionStorage.getItem('site_name');

    if(id && siteName){
      this.siteName = siteName;
      this.getSiteDetails(id);
    }else{
      window.history.back();
    }
    // this.route.queryParams.subscribe(params => {
    //   console.log("params",params);
    //   this.siteName = params.site_name;
    //   this.getSiteDetails(params.id);
      
    // });
  }

  imageClickHandler(e:any) {

      this.imageClick = true;
  }

  lbBox(e : any){
      this.imageClick = false;
  }

  ngAfterViewInit(){
    // this.accessibilityData(0);
  }

  // To Download the canvas to PDF
  downloadChart(templateRef:any){

    /* Creating a PDF file from a canvas element. It first gets the canvas element by its
    ID, then converts it to a PNG image using the `toDataURL()` method. It then creates a new
    instance of the jsPDF library, adds the PNG image to the PDF using the `addImage()` method, and
    saves the PDF file with the name "canvas.pdf" using the `save()` method. */
    this.openSnackBar("Downloading in progress !!", "Close", 2000);
    var data:any = document.getElementById(templateRef);  //Id of the table
    html2canvas(data).then(canvas => {
        console.log("klkl",templateRef);
        // Few necessary setting options  
        let imgWidth = 225;      
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png')  
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF   
        pdf.addImage(contentDataURL, 'PNG', 0, 20, imgWidth, imgHeight);
        /* extracting the index value from a string by splitting it using the underscore
        character as a delimiter. It then uses the extracted index value to access an element in an
        array and assigns the value to the variable `canvasName`. The array is selected based on the
        value of `activeButton` property of an object `cardsHeading`. */
        let index = templateRef.split("_")[1];
        let canvasName = this.cardsHeading[this.activeButton].title[index];
        pdf.save(canvasName + '.pdf'); // Generated PDF 
      });
  }
  
  openSnackBar(message: any, action: any, time: any) {
   let config = new MatSnackBarConfig();
   config.verticalPosition = 'top';
   this._snackBar.open(message, 'Close', {
    duration: time
   });
    
   }

  onChangeIndicators(event:any){
    this.selectedTabName = this.tabList[event];
    this.showHistoricalPage = false;
    this.activeButton = event;
    let siteDetails = this.siteDetails[0]
    this.destroy();
    setTimeout(() => {
      this.cardsHeading[event].key.forEach((id:any, index:any) => {
        let columnKey:any = [];
        let data : any;
        let finalColumn : any;
        this.column.forEach((suffix:any) => {
          finalColumn = id+'_'+suffix;
          columnKey.push(siteDetails[finalColumn]);
        })
        let title = this.cardsHeading[event]['title'][index]
        this.cardsHeading[event]['showHide'][title] =  columnKey.includes(0) ? false : true;
        this.cardsHeading[event] = {...this.cardsHeading[event]}
        console.log('this.cardsHeading[i][keys]',this.cardsHeading[event])
      data = JSON.parse(JSON.stringify(this.data))
      data.datasets[0].data = columnKey;
      console.log("ddd", this.data, columnKey)
        this.chartRef.push( this.chartService.verticalBar(id, data, this.type));
      })

      let imageObject = []
      // let photosIndex =1;
      console.log("keyyyyy",siteDetails);
      for(let key in siteDetails){
        
        if(key.includes(this.photosKey[event]) && siteDetails[key] != ''){
        imageObject.push({image:siteDetails[key],thumbImage:siteDetails[key]})
        // photosIndex++;
        }
      }

      console.log("ddd",imageObject)
      this.imageObject = [...imageObject]
    }, 500);

    // Creating Promise for the closer of Loader as this is a Async process
    let loaderPromise = new Promise((resolve, reject) => {
      resolve("Promise is resolved");
    })
    loaderPromise.then(response => {
      // this.contentService.loaderSubscriber.next(false);
    })
  }

  getSiteDetails(id:any){
    // this.contentService.loaderSubscriber.next(true);
    this.homeService.getSiteDetails(id).subscribe(response =>{
      if(response['success']){
        this.allImageData = []
    // this.contentService.loaderSubscriber.next(false);
        this.siteDetails = response?.data;
        console.log("sitedetails",this.siteDetails,);
        
        this.siteAbout = this.siteDetails[0].site_about;
        this.scoreCardObj = {
          overall_score : this.siteDetails[0].overall_score,
          overall_accessibility : this.siteDetails[0].overall_accessibility,
          overall_amenities: this.siteDetails[0].overall_amenities,
          overall_hygiene : this.siteDetails[0].overall_hygiene,
          overall_safety : this.siteDetails[0].overall_safety
        }
        // let photosIndex = 1
        for(let key in this.siteDetails[0]){
          if(key.indexOf('photos') > -1 && this.siteDetails[0][key] != ''){
            console.log("dd sites",key,this.allImageData, this.siteDetails[0], this.siteDetails[0].key)
          this.allImageData.push({image:this.siteDetails[0][key],thumbImage:this.siteDetails[0][key]});
          // photosIndex++;
          }
        }
        
      // }
      }
    })
  }

  isUrl(url:any) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(url);
  }

async getBase64ImageFromUrll(data:any){
    let isUrl = this.isUrl(data);
    console.log("URLSsss",data, isUrl);
    if(isUrl){
      var res = await fetch(data+"?stopGivingMeHeadaches=true");
      var blob = await res.blob();
      return new Promise((resolve, reject) => {
        var reader  = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result?.toString());
        }, false);
        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      })
    }
  }

  // zip download for all the images
  downloadAll(){
    this.openSnackBar("Images ZIP download in progress !!", "Close", 2000);
    let zip = new JSZip();
    let zipFolder = zip.folder("images");
    console.log("imageObject",this.imageObject);
    this.imageObject.forEach(async (iterator:any,i:any) => {
     await this.getBase64ImageFromUrll(iterator.image).then((res:any)=>{
      console.log("theResp",res);
      
        let data = iterator.image.split('.');
        let splitForDocNme = iterator.image.split('/');
        let docName = splitForDocNme[splitForDocNme['length']-1]
        let lastInd = data[data.length-1];
        let imgData = res.toString().split(',');
        console.log('docName',docName);
        console.log('lastInd',lastInd);
        zipFolder?.file(docName,imgData[1], { base64: true });
      })
    });
    setTimeout(() => {
      // Zip folder for the images
      zip.generateAsync({type:"blob"})
      .then((content) => {
          saveAs(content, 'images.zip');
      });
      this.downloadAllCharts();
    }, 900);
  }
  
  async downloadAllCharts(){
    this.openSnackBar("Canvas ZIP download in progress !!", "Close", 5000);
    var allClass:any = document.getElementsByClassName('bg-white shadow mt-3');
    console.log("allClass",allClass);
      
    let zip = new JSZip();
    let zipPdf = zip.folder("charts");
    let prom:any[] = [];
    for(let i=0 ; i<=allClass.length;i++){
        let tem = await html2canvas(allClass[i]).then(canvas => {
        let imgWidth = 230;      
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png')  
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF   
        pdf.addImage(contentDataURL, 'PNG', 0, 20, imgWidth, imgHeight);
        let strName= this.cardsHeading[this.activeButton].title[i];
        strName = strName.replace(/ +/g, "");
        const replacedStr = strName.replace(/\//g, ' or ');
        console.log(replacedStr);
        
        // pdf.save(replacedStr + '.pdf').textWithLink(); // Generated PDF 
        return zipPdf?.file((replacedStr? replacedStr : "Static_"+i) + '.pdf',pdf.output('blob'));
        }).catch( error =>  console.log(error) );;
        prom.push(tem);
    }
    setTimeout(() => {
      zip.generateAsync({type:"blob"})
      .then((content) => {
          saveAs(content, 'charts.zip');
      });
    },500)
  }

  destroy(){
    this.chartRef.forEach(el => {
      if(el && el.hasOwnProperty('id'))
      el.destroy();
    })
  }
 
  redirectToAbout(event:any){
    this.showHistoricalPage = true;
    this.activeButton = event;
  //   this.route.params.subscribe((params: Params) => {
  //     let id = params['id'];
  //     console.log(id);
  //     this.router.navigate(
  //       ['/historical-sites'],
  //       { queryParams: { id: id?.id } }
  //     );
  //  })
  }

}
