import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button'
import { NgImageSliderModule } from 'ng-image-slider';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from './shared/shared.module';
import {MatTabsModule} from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { AccessibilityComponent } from './pages/accessibility/accessibility.component'
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { HistoricalSitesComponent } from './pages/historical-sites/historical-sites.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CustomPipe } from './pages/historical-sites/customPipe';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { DocumentsComponent } from './pages/documents/documents.component';
import { IframeDialogComponent } from './pages/documents/iframe-dialog/iframe-dialog.component';
import { PaginatorComponent } from './pages/paginator/paginator.component';
import { FilterPipe } from './filter.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AccessibilityComponent,
    HistoricalSitesComponent,
    AboutUsComponent,
    CustomPipe,
    DocumentsComponent,
    IframeDialogComponent,
    PaginatorComponent,
    FilterPipe


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgImageSliderModule,
    MatPaginatorModule,
    MatTooltipModule,
    SharedModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBgT0XnBlw6zJnT5EVrZlmA0Tnbb0CVSb0'
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule
  ],
  entryComponents:[
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {duration: 5000}
  }],
  bootstrap: [AppComponent],
  exports:[MatDialogModule,MatButtonModule,NgImageSliderModule,MatPaginatorModule,MatTooltipModule],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
    ],
})
export class AppModule { }
