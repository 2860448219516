<footer class="footerBg">
    <div class="container-fluid py-4" style="position:relative">
  
        <div class="row">
            <div class="col-sm mb-3 col-lg px-5 pt-2">
                <div class=" h-100  align-items-stretch d-block">
                  <div class="col-sm-12 mb-3 col-lg-auto align-self-end">
                    <h6>Connect with us on social media</h6>
                    <ul class="nav mt-3">
                        <li class="nav-item">
                            <a href="#" class="sm circle" title="facebook">
                                <svg id="facebook-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_3674" data-name="Path 3674" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_3675" data-name="Path 3675" d="M14,13.5h2.5l1-4H14v-2c0-1.03,0-2,2-2h1.5V2.14A28.166,28.166,0,0,0,14.643,2,4.336,4.336,0,0,0,10,6.7V9.5H7v4h3V22h4Z"/>
                                  </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="sm circle" title="youtube">
                                <svg id="youtube-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_3676" data-name="Path 3676" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_3677" data-name="Path 3677" d="M21.543,6.5A31.058,31.058,0,0,1,22,12a31.058,31.058,0,0,1-.457,5.5A2.811,2.811,0,0,1,19.6,19.524C17.9,20,12,20,12,20s-5.893,0-7.605-.476A2.811,2.811,0,0,1,2.457,17.5,31.058,31.058,0,0,1,2,12a31.058,31.058,0,0,1,.457-5.5A2.811,2.811,0,0,1,4.395,4.476C6.107,4,12,4,12,4s5.9,0,7.6.476A2.811,2.811,0,0,1,21.543,6.5ZM10,15.5,16,12,10,8.5Z"/>
                                </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="sm-3 circle" title="twitter">
                                <svg id="twitter-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_3678" data-name="Path 3678" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_3679" data-name="Path 3679" d="M22.162,5.656a8.384,8.384,0,0,1-2.4.658A4.2,4.2,0,0,0,21.6,4a8.358,8.358,0,0,1-2.656,1.015,4.182,4.182,0,0,0-7.126,3.814A11.874,11.874,0,0,1,3.2,4.459a4.188,4.188,0,0,0,1.294,5.584A4.168,4.168,0,0,1,2.6,9.52v.052a4.185,4.185,0,0,0,3.354,4.1,4.21,4.21,0,0,1-1.889.072A4.185,4.185,0,0,0,7.97,16.65a8.394,8.394,0,0,1-6.191,1.732,11.83,11.83,0,0,0,6.41,1.88,11.819,11.819,0,0,0,11.9-11.9c0-.18,0-.362-.013-.54a8.5,8.5,0,0,0,2.087-2.165Z"/>
                                  </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
            <div class="col-sm mb-3 col-lg px-5 pt-2">
                <h6>Links</h6>
                <ul class="nav flex-column mt-3">
                    <li class="nav-item">
                        <a  routerLink="/home">Home</a>
                    </li>
                    <li class="nav-item">
                        <a  routerLink="/about-us">About Us</a>
                    </li>
                </ul>
            </div>
    
            <div class="col-sm mb-3 col-lg-auto text-nowrap px-5 pt-2">
                <h6>Contact Us</h6>
                <div class="mt-3">Ministry of Tourism - Govt. of India</div>
     
            </div>
            
        </div>
    </div>
</footer>
<div class="back-to-top" (click)="scrollToTop()">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/><path d="M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20 M12,22c5.52,0,10-4.48,10-10c0-5.52-4.48-10-10-10 C6.48,2,2,6.48,2,12C2,17.52,6.48,22,12,22L12,22z M11,12l0,4h2l0-4h3l-4-4l-4,4H11z"/></g></svg>
</div>
