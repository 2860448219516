import { Component, HostListener, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  pageYoffset = 0;
  @HostListener('window:scroll', ['$event']) onScroll(){
    this.pageYoffset = window.pageYOffset;
  }
  totalVisitor = 0;
  constructor(
    private scroll: ViewportScroller
  ) { }

  ngOnInit(): void {

  }

  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

}
