
<mat-sidenav-container class="filter-container py-5" (backdropClick)="close('backdrop')">
  <mat-sidenav class="sideNavbar" #sidenav (keydown.escape)="close('escape')" disableClose>
    <!-- 1st Filter Card -->
    <div class="col-sm-12 card-col1">
        <div class="bg-white shadow mainDiv">
          <div class="col-md-12 filterByDiv">
            <strong>Filter by</strong>
            <!-- <i class="fa fa-filter" style="float:right;font-size:20px"></i> -->
          </div>
          <div class="card-body highlights-card-height1 py-2" style="overflow-y: auto;max-height: 40px;">
            
            <div class="col-sm-12 mt-1" *ngIf="hideLocation">
              <!-- <input type="text" class="form-control siteName" id="siteName" placeholder="Enter Site Name" style="width: 100%;" [(ngModel)]="filterObject.siteName" (ngModelChange)="siteNameValue($event)"> -->

              <div class="accordion" id="siteNameAccordian">
                <div class="accordion-item" style="border: none;">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSiteName" aria-expanded="true" aria-controls="collapseSiteName">
                      Site Name
                    </button>
                  </h2>
                  <div id="collapseSiteName" class="accordion-collapse collapse hide" aria-labelledby="headingOne" data-bs-parent="#siteNameAccordian" style="background-color: #f0f9ff">
                    <div class="accordion-body">
                      <div *ngIf="allSiteNameData.length">
                      <ng-container class="form-check" *ngFor="let item of allSiteNameData.slice(0,3)">
                        <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'site-'+item.id" (click)="isCheckboxTickedForSiteName(item)" [(ngModel)]="item.checked">
                        <label class="labelWrap" [for]="'site-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                      </ng-container>

                      <div *ngIf="isReadMoreSiteName">
                        <ng-container class="form-check" *ngFor="let item of allSiteNameData.slice(3,allSiteNameData.length)">
                          <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'site-'+item.id" (click)="isCheckboxTickedForSiteName(item)" [(ngModel)]="item.checked">
                          <label class="labelWrap" [for]="'site-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                        </ng-container>
                      </div>

                      <span class="showMoreTab" (click)="isReadMoreSiteName = !isReadMoreSiteName" *ngIf="allSiteNameData.length > 3">{{!isReadMoreSiteName ? 'Show More..' : 'Show Less'}}</span>

                      </div>
                      <span class="noRecords" *ngIf="!allSiteNameData.length">No Records Found</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>

            </div>
            
            <div class="col-sm-12 mt-3">
              <strong>Location</strong>
            </div>

            <!-- Accordian Starts from Here -->
            <!-- Accordian 1 -->
            <div class="col-sm-12 mt-2"> 
              <div class="accordion" id="zone" style="display: none">
                <div class="accordion-item" style="border: none;">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Zone
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse hide" aria-labelledby="headingOne" data-bs-parent="#zone">
                    <div class="accordion-body">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="zone"  value="Zone A">
                        <label class="form-check-label" for="zone">
                          Zone A
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- Accordian 2 -->
              <div class="accordion" id="state">
                <div class="accordion-item" style="border: none;">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      State
                    </button>
                  </h2>
                  <div *ngIf="!isExpanded" id="collapseTwo" class="accordion-collapse collapse hide" aria-labelledby="headingOne" data-bs-parent="#state" style="background-color: #f0f9ff">
                    <div class="accordion-body">
                      <div *ngIf="allStateData.length">
                      <ng-container class="form-check" *ngFor="let item of allStateData.slice(0,3); let i = index">
                        <input class="form-check-input" type="checkbox" [id]="'state-'+item.id" [value]="item.id" (click)="isCheckboxTicked(item, i)" [(ngModel)]="item.checked" >
                        <label class="form-check-label labelWrap" [for]="'state-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                      </ng-container>

                      <div *ngIf="isReadMore">
                      <ng-container class="form-check" *ngFor="let item of allStateData.slice(3,allStateData.length); let i = index">
                        <input class="form-check-input" type="checkbox" [id]="'state-'+item.id" [value]="item.id" (click)="isCheckboxTicked(item, i)" [(ngModel)]="item.checked" >
                        <label class="form-check-label labelWrap" [for]="'state-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                      </ng-container>
                      </div>

                      <span class="showMoreTab" (click)="isReadMore = !isReadMore" *ngIf="allStateData.length > 3">{{!isReadMore ? 'Show More..' : 'Show Less'}}</span>
                      </div>
                      <span class="noRecords" *ngIf="!allStateData.length">No Records Found</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="accordion" id="siteNameAccordian">
                <div class="accordion-item" style="border: none;">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSiteName" aria-expanded="true" aria-controls="collapseSiteName">
                      Site Name
                    </button>
                  </h2>
                  <div *ngIf="!isExpanded" id="collapseSiteName" class="accordion-collapse collapse hide" aria-labelledby="headingOne" data-bs-parent="#siteNameAccordian" style="background-color: #f0f9ff">
                    <div class="accordion-body">
                      <div *ngIf="allSiteNameData.length">
                      <ng-container class="form-check" *ngFor="let item of allSiteNameData.slice(0,3)">
                        <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'site-'+item.id" (click)="isCheckboxTickedForSiteName(item)" [(ngModel)]="item.checked">
                        <label class="labelWrap" [for]="'site-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                      </ng-container>

                      <div *ngIf="isReadMoreSiteName">
                        <ng-container class="form-check" *ngFor="let item of allSiteNameData.slice(3,allSiteNameData.length)">
                          <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'site-'+item.id" (click)="isCheckboxTickedForSiteName(item)" [(ngModel)]="item.checked">
                          <label class="labelWrap" [for]="'site-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                        </ng-container>
                      </div>

                      <span class="showMoreTab" (click)="isReadMoreSiteName = !isReadMoreSiteName" *ngIf="allSiteNameData.length > 3">{{!isReadMoreSiteName ? 'Show More..' : 'Show Less'}}</span>

                      </div>
                      <span class="noRecords" *ngIf="!allSiteNameData.length">No Records Found</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <!-- Destination accordian -->
              <div class="accordion" id="destination">
                <div class="accordion-item" style="border: none;">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDestination" aria-expanded="true" aria-controls="collapseDestination">
                      Destination
                    </button>
                  </h2>
                  <div *ngIf="!isExpanded" id="collapseDestination" class="accordion-collapse collapse hide" aria-labelledby="headingOne" data-bs-parent="#destination" style="background-color: #f0f9ff">
                    <div class="accordion-body">
                      <div *ngIf="destinationData.length">
                      <ng-container class="form-check" *ngFor="let item of destinationData.slice(0,3)">
                        <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'dest-'+item.id" (click)="isCheckboxTickedForDestination(item)" [(ngModel)]="item.checked">
                        <label class="labelWrap" [for]="'dest-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                      </ng-container>

                      <div *ngIf="isReadMoreDestination">
                        <ng-container class="form-check" *ngFor="let item of destinationData.slice(3,destinationData.length)">
                          <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'dest-'+item.id" (click)="isCheckboxTickedForDestination(item)" [(ngModel)]="item.checked">
                          <label class="labelWrap" [for]="'dest-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                        </ng-container>
                      </div>

                      <span class="showMoreTab" (click)="isReadMoreDestination = !isReadMoreDestination" *ngIf="destinationData.length > 3">{{!isReadMoreDestination ? 'Show More..' : 'Show Less'}}</span>

                      </div>
                      <span class="noRecords" *ngIf="!destinationData.length">No Records Found</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>

              <!-- Accordian 3 -->
              <div class="accordion" id="district">
                <div class="accordion-item" style="border: none;">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                      District
                    </button>
                  </h2>
                  <div *ngIf="!isExpanded" id="collapseThree" class="accordion-collapse collapse hide" aria-labelledby="headingOne" data-bs-parent="#district" style="background-color: #f0f9ff">
                    <div class="accordion-body">
                      <div *ngIf="allDistrictData.length">
                      <ng-container class="form-check" *ngFor="let item of allDistrictData.slice(0,3)">
                        <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'district-'+item.id" (click)="isCheckboxTickedForDistrict(item)" [(ngModel)]="item.checked">
                        <label class="labelWrap" [for]="'district-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                      </ng-container>

                      <div *ngIf="isReadMoreDistrict">
                        <ng-container class="form-check" *ngFor="let item of allDistrictData.slice(3,allDistrictData.length)">
                          <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'district-'+item.id" (click)="isCheckboxTickedForDistrict(item)" [(ngModel)]="item.checked">
                          <label class="labelWrap" [for]="'district-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                        </ng-container>
                      </div>

                      <span class="showMoreTab" (click)="isReadMoreDistrict = !isReadMoreDistrict" *ngIf="allDistrictData.length > 3">{{!isReadMoreDistrict ? 'Show More..' : 'Show Less'}}</span>

                      </div>
                      <span class="noRecords" *ngIf="!allDistrictData.length">No Records Found</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>

              <!-- Accordian 4 -->
              <div class="accordion" id="thematic">
                <div class="accordion-item" style="border: none;">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                      Thematic
                    </button>
                  </h2>
                  <div *ngIf="!isExpanded" id="collapseFour" class="accordion-collapse collapse hide" aria-labelledby="headingOne" data-bs-parent="#thematic" style="background-color: #f0f9ff">
                    <div class="accordion-body">
                      <div *ngIf="allThemeticData.length">
                      <ng-container class="form-check" *ngFor="let item of allThemeticData.slice(0,3)">
                        <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'theme-'+item.id" (click)="isCheckboxTickedForThematic(item)" [(ngModel)]="item.checked">
                        <label class="labelWrap" [for]="'theme-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                      </ng-container>

                      <div *ngIf="isReadMoreThematic">
                        <ng-container class="form-check" *ngFor="let item of allThemeticData.slice(3,allThemeticData.length)">
                          <input class="form-check-input" type="checkbox" [value]="item.id" [id]="'theme-'+item.id" (click)="isCheckboxTickedForThematic(item)" [(ngModel)]="item.checked">
                          <label class="labelWrap" [for]="'theme-'+item.id" style="margin-left: 5px;" [matTooltip]="item?.name">{{item?.name}}</label><br>
                        </ng-container>
                      </div>
                      <span class="showMoreTab" (click)="isReadMoreThematic = !isReadMoreThematic" *ngIf="allThemeticData.length > 3">{{!isReadMoreThematic ? 'Show More..' : 'Show Less'}}</span>
                      </div>
                      <span class="noRecords" *ngIf="!allThemeticData.length">No Records Found</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
            </div>
            <!-- Accordian Ends Here -->

            <div class="col-sm-12 mt-3">
              <strong>Advanced Filter Score</strong>
            </div>

            <div class="col-sm-12" *ngFor="let item of filterData.filterHead">
              <label>{{item.name}}</label>
              <div>
                <input type="range"  [id]="item.variable" [name]="item.variable" [min]="filterData.minValue" [max]="filterData.maxValue" [step]="filterData.step" list="markers" style="width: 100%" [(ngModel)]="filterObject[item.variable]">
                <datalist id="markers">
                  <option *ngFor="let scaleValue of filterData.scale" [value]="scaleValue" [label]="scaleValue"></option>
                </datalist>
              </div>
              <hr>
            </div>
          </div>
          <div class="filterbuttons">
          <div class="col-sm-12 actionButtons">
            <button class="btn btn-outline-primary" style="margin-right: 10px; border-radius: 1px;" (click)="resetFilter()">RESET</button>
            <button class="btn btn-primary" type="submit" id="submit" style="margin-right: 20px; border-radius: 1px; background-color: #0262af;" (click)="applyFilter(); sidenav.toggle()">APPLY</button>
          </div>
        </div>
        </div>
      </div>

  </mat-sidenav>

  <mat-sidenav-content>
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
      <ng-container *ngIf="carouselImages?.length; else noData">
        <div class="carousel-indicators">
          <ng-container *ngFor="let item of carouselImages;let i = index">
            <button type="button" class="carousel-indicators-icon" data-bs-target="#carouselExampleCaptions"
              [attr.data-bs-slide-to]="i" [ngClass]="{'active': i==0}" aria-current="true" aria-label="Slide"></button>
          </ng-container>
        </div>
    
        <div class="carousel-inner">
          <ng-container *ngFor="let item of carouselImages;let i = index">
            <div class="carousel-item " [ngClass]="{'active': i==0}">
              <img [src]="item" class="d-block w-100 " style="height: auto; max-height: 300px;" alt="banner_modiji">
            </div>
          </ng-container>
        </div>
      </ng-container>
    
      <ng-template #noData>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="/assets/home/banner_modiji@1x.png" class="d-block w-100" alt="banner_modiji">
          </div>
        </div>
      </ng-template>
      <button *ngIf="carouselImages?.length" class="carousel-control-prev" type="button"
        data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true" style="border-radius: 16px"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button *ngIf="carouselImages?.length" class="carousel-control-next" type="button"
        data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true" style="border-radius: 16px"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <!-- <div class="bg-pattern text-center wrapper">
      <div class="green-text-cont p-2 lines">
          <div class="ticker">
            <span class="text-decorate">
              {{tickerString}}
            </span>
          </div>
      </div>
    </div> -->
    <div>
      <!-- Starting carousel... -->
    
    <div>
      <div class="container-fluid">
        <div class="row">
          
    
          <!-- 2nd Map Card -->
          <div class="col-md-6 col-sm-12 card-col">
            <ngx-loader [show]="showMap" > </ngx-loader>
            <div class="bg-white shadow mt-3">
              <div class="card-body highlights-card-height py-2 map-col" style="overflow-y: auto;max-height: 40px;">
                <div fxFlex="100" class="col-sm-12 map-full-card-content">
                  <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="resetZoom" [minZoom]="4.4" [disableDefaultUI]="false"
                    [zoomControl]="true" [fitBounds]="true">
                    <agm-marker-cluster
                      imagePath="https://raw.githubusercontent.com/googlearchive/js-marker-clusterer/gh-pages/images/m">
                      <agm-marker *ngFor="let m of mapData; let i = index" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label"
                        (markerClick)="clickedMarker(m, i)" [agmFitBounds]="true">
                      </agm-marker>
                    </agm-marker-cluster>
                  </agm-map> -->
                  <div id="map"></div>
                </div>
              </div>
            </div>
          </div>
    
          <!-- 3rd Table Card -->
          <div class="col-md-6 col-sm-12 card-col">
            <div class="bg-white shadow mt-3">
              <div class="card-body highlights-card-height py-2 table-col row" style="overflow-y: auto;max-height: 40px;">
                <div class="col-sm-12 mt-1">
                  <!-- d-flex align-items-center justify-content-between -->
                  <div class="d-flex align-items-baseline float-right" style="float: right;">
                    <span style="display: none;">Showing result for 
                        <span *ngIf="tableData.length > 10">1-10</span>
                        <span *ngIf="tableData.length < 11">1-{{tableData.length}}</span> of {{tableData.length}}</span>
                        <i class="fa fa-cloud-download mx-2" aria-hidden="true" style="color:#0262af; cursor: pointer; font-size: 20px; float: right; position: relative" matTooltip="Download" (click)="downloadTableCSV()"></i>
                        <i class="fa fa-filter" style="font-size: 21px;margin-top: 2px; cursor: pointer;" (click)="sidenav.open()" matTooltip="Apply Filter"></i>
                    <!-- <button class="btn btn-success btn-sm mb-2" style="float: right;background-color: #4fbb61;border-radius: 2px;margin-right: 12px;" (click)="downloadTableCSV()">DOWNLOAD
  
                      <i class="fa fa-cloud-download"></i>
                    </button> -->
                  </div>
                </div>
                <div class="col-sm-12 overall-score-table">
                  <ngx-loader [show]="showTable" > </ngx-loader>
                  <div class="table-responsive inner" style="height: 450px;">
                    <table class="table table-hover" id="dataTable">
                      <thead  class="bg-blue position-sticky top-0">
                        <tr>
                          <th class="text-white border-0" style="width:50px">S.No.</th>
                          <th class="text-white border-0" style="width: 150px">State</th>
                          <th class="text-white border-0" style="width: 200px">Site</th>
                          <th class="text-white border-0" style="width: 117px">Overall Site Score</th>
                          <th class="text-white border-0 range">Accessibility</th>
                          <th class="text-white border-0 range">Amenities</th>
                          <th class="text-white border-0 range">Hygiene</th>
                          <th class="text-white border-0" style="width: 120px;">Safety & Security</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of tableData;let i = index" (click)="redirectRowData(item)" style="cursor: pointer;">
                          <td class="fix">{{(i + 1) + (currentPage * pageSize )}}</td>
                          <td class="fix">{{item?.state_name}} <i class="fa fa-info-circle" style="color:gray" [matTooltip]="item?.state_name"></i></td>
                          <td class="fix" [matTooltip]="item?.site_name">{{ (item?.site_name.length > 20)? (item?.site_name | slice:0:20)+'...':(item?.site_name) }}</td>
                          <td class="rangetd">{{item?.overall_score}}</td>
                          <td class="rangetd">{{item?.overall_accessibility}}</td>
                          <td class="rangetd">{{item?.overall_amenities}}</td>
                          <td class="rangetd">{{item?.overall_hygiene}}</td>
                          <td class="rangetd">{{item?.overall_safety}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div *ngIf="!tableData.length">
                      <img src="../../../assets/images/noDataFound.png" style="margin-top: 10%; margin-left: 25%;">
                    </div>
                  </div>
                  <div class="pagination-wrapper">
                    <mat-paginator [length]="total" #paginator
                    [pageSize]="pageSize"
                    (page)="pageChangeEvent($event)">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
    <div class="modal fade" id="homeModal" tabindex="-1" aria-labelledby="homeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" style="width: 570px;">
        <div class="modal-content">
          <div class="modal-header" style="border:none">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- <div class="col-sm-8">
                <h1 class="title text-center">Welcome to DAY - NRLM</h1>
                <h4 class="wel-para p-4">
                  <ng-container>Deendayal Antyodaya Yojana - National Rural Livelihoods Mission (DAY-NRLM) was launched by the Ministry of Rural Development (MoRD), Government of India in June 2011. Aided in part through investment support by the World Bank, the Mission aims at creating efficient and effective institutional platforms of the rural poor, enabling them to increase household income through sustainable livelihood enhancements and improved access to financial services.</ng-container>
                </h4>
    
              </div>
              <div class="col-sm-4">
                <ng-container *ngIf="popupDoc.length; else defaultImage"><img class="img-fluid" style="height: 250px;"
                    src="{{popupDoc[0]?.file_url}}" alt="welcome-popup img"></ng-container>
                <ng-template #defaultImage><img class="img-fluid" style="height: 250px;"
                    src="/assets/images/welcome-popup.png" alt="welcome-popup img"></ng-template>
              </div> -->
             <div class="col-12">
                    <img src="../../../assets/images/G20theme.png" style="width: 100%;
            height: 262px;" alt="">
             </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- <mat-sidenav-container
    class="filter-container" (backdropClick)="close('backdrop')">
  <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose>
    <p><button mat-button (click)="close('toggle button')">Toggle</button></p>
  </mat-sidenav>

  <mat-sidenav-content>
    
  </mat-sidenav-content>
</mat-sidenav-container> -->