<!-- <section class="py-1 small d-none d-lg-block sectionClass">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-5 col-lg-5 col-sm-5">
                <ul class="nav">
                    
                    <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" [routerLink]="['/multimedia/feedback']"
                            routerLinkActive="active">Feedback</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" routerLinkActive="active" routerLink="/sitemap">Sitemap</a>
                    </li>
                    <li class="nav-item" *ngIf="!isLoggedIn">
                        <a class="nav-link py-0 navHover" routerLinkActive="active" routerLink="/login">Login</a>
                    </li>
                    <li class="nav-item" *ngIf="isLoggedIn">
                        <a class="nav-link py-0 navHover" routerLinkActive="active" routerLink="/admin/content">Admin Content</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-7 col-lg-7 col-sm-7">
                <ul class="nav  justify-content-end">
                    <li class="nav-item">
                        <a href="#main-content" class="nav-link py-0 border-end navHover">Skip to main content</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" [routerLink]="['/screen-reader-access']">Screen Reader
                            Access</a>
                    </li>
                    <li class="nav-item">
                        <ng-container *ngFor="let data of textSize">
                            <span (click)="setFontSize(data)" class="text-{{data}} textIcon circleHover" style="cursor: pointer;border: none;"
                                [ngClass]="currentTextSize == data ? 'active' : '' ">A</span>
                        </ng-container>
                    </li>
                    <li  class="nav-item">
                        <a href="https://www.facebook.com/aajeevika" target="_blank" class="nav-link py-0 circleHover">
                        <i class="fa fa-facebook" aria-hidden="true" style="margin-left: -5px;"></i>
                      </a>
                      </li>
                  
                      <li  class="nav-item">
                        <a href="https://twitter.com/DAY_NRLM" target="_blank" class="nav-link py-0 circleHover">
                          <i class="fa fa-twitter" aria-hidden="true" style="margin-left: -5px;"></i>
                        </a>
                      </li>
                      
                  
                      <li  class="nav-item">
                        <a href="http://www.youtube.com/user/aajeevikaNRLM" target="_blank" class="nav-link py-0 circleHover">
                          <i class="fa fa-youtube" aria-hidden="true" style="margin-left: -5px;"></i>
                        </a>
                  
                  
                      </li>
                </ul>
            </div>
            
        </div>
    </div>
</section> -->
<section class="py-2 bg-white shadow position-sticky top-0" style="z-index: 999;">
    <div class="container-fluid">
        <div class="row justify-content-between align-items-center">
            <div class="col-auto">
                 <img src="../../../assets/home/Mask Group 1.png" class="img-fluid me-4" alt="">
                 <img src="../../../assets/home/image014.png" width="180" class="img-fluid me-4" alt="">
            </div>
            <div class="col-auto">
                 <img src="../../../assets/home/Group 2.png" class="img-fluid me-2" alt="">
                 <img src="../../../assets/home/Image 6.png" class="img-fluid me-2" alt="">
                 <img src="../../../assets/home/image-removebg-preview (19).png" class="img-fluid me-2" alt="">
            </div>
        </div>
    </div>
</section>


<nav class="nav-ajvk bg-primary nav-primary position-fixed" style="z-index: 999;">
    <div class="container-fluid p-0" style="position:fixed;">
        <div class="row px-3">
            
                <ul class="cust-navbar">
                    <li routerLinkActive="active" class="home" style="margin-left: 25px">
                        <a class="nav-link" routerLink="/home">
                            <i class="fa fa-home fas" style="font-size: large;"></i>
                        </a>
                    </li>
                    <li routerLinkActive="active" class="about-us">
                        <a class="nav-link" routerLink="/about-us">
                            About
                        </a>
                    </li>
                    <li routerLinkActive="active" class="about-us">
                        <a class="nav-link" routerLink="/documents">
                            Documents
                        </a>
                    </li>
                </ul>
                      
        </div>
    </div>
</nav>