<div class="customNavbar bg-white py-5 index">
    <nav class="nav-ajvk bg-primary nav-primary">
        <div class="container-fluid">
            <div class="row px-3">
                <ul class="cust-navbar">
                    
                        <span style="opacity:0.7; color: white; font-size: 13px;">Site Name : </span>
                        <span style="color: white; font-size: 13px"><span (click)="redirectToAbout(0)" [ngClass]="showHistoricalPage ? 'boldClass' : 'redirectToAbout'">
                            {{siteName}}
                        </span></span>
                    
                    <li style="margin-left: 20px; ">
                        <a class="nav-link" (click)="onChangeIndicators(1)" [class.phase-active]="activeButton === 1">
                            Accessibility
                        </a>
                    </li>
                    <li  style="margin-left: 20px;">
                        <a class="nav-link" (click)="onChangeIndicators(2)" [class.phase-active]="activeButton === 2">
                            Amenities
                        </a>
                    </li>
                    <li  style="margin-left: 20px;">
                        <a class="nav-link" (click)="onChangeIndicators(3)" [class.phase-active]="activeButton === 3">
                            Hygiene
                        </a>
                    </li>
                    <li  style="margin-left: 20px;">
                        <a class="nav-link" (click)="onChangeIndicators(4)" [class.phase-active]="activeButton === 4">
                            Safety & Security
                        </a>
                    </li>
                </ul>    
            </div>
        </div>
    </nav>
    <div class="path">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex align-items-center p-3">
                    <span style="opacity:0.7; font-size: 12px;" class="regular"><span style="cursor:pointer;" routerLink="/home">Home</span> / <span (click)="redirectToAbout(0)" [ngClass]="showHistoricalPage ? 'boldClass' : 'redirectToAbout'">
                        {{siteName}}
                    </span> <span *ngIf="!showHistoricalPage" style="font-weight: 700;">/ {{selectedTabName}}</span></span>
                    <span class="regular"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="barChart index" [ngClass]="{'override-index' : imageClick}" *ngIf="!showHistoricalPage">
    <div class="container-fluid">
        <!-- OverAll Score Bar Chart -->
        <i class="fa fa-cloud-download" aria-hidden="true" style="color:#0262af; cursor: pointer; font-size: 20px; float: right; margin-top: -32px; position: relative" matTooltip="Download" (click)="downloadAll()"></i>
        <div class="row" style="padding: 5px;">
            <div class="row" style="justify-content: center;">
                <div class="col-10 mb-3">
                    <div style="text-align:-webkit-center;">
                        <ng-image-slider #nav
                        [images]="imageObject"
                        [infinite]="false"
                        [autoSlide]="1"
                        [imageSize]="{height: 200,space: 15}"
                        [slideImage]="2"
                        (imageClick)="imageClickHandler($event)" (lightboxClose)="lbBox($event)"
                        [showArrow]="imageObject.length < 6 ? false : true"
                        >
                        </ng-image-slider>
                      </div>
                </div>
            </div>
           
            <ng-container  *ngFor="let cardGraph of cardsHeading[activeButton]['title'];let index = index">
                <div class="col-md-6 overallScoreCard" [id]="'chartPDF_'+index" *ngIf="cardsHeading[activeButton]['showHide'][cardGraph]">
                    <div class="bg-white shadow mt-3">
                        <div class="chartHeader mt-3 d-flex align-items-center justify-content-between">
                            <h5>{{cardGraph}}</h5>
                            <i class="fa fa-cloud-download mx-2" aria-hidden="true" style="color:#0262af; cursor: pointer; font-size: 20px; float: right; position: relative" matTooltip="Download" (click)="downloadChart('chartPDF_'+index)"></i>
                            <!-- <i class='fa fa-download' style='color:#4dcb4f; font-size: 22px; cursor: pointer;' matTooltip="Download Chart" (click)="downloadChart('chartPDF_'+index)"></i> -->
                        </div>
                        <div class="card-body highlights-card-heights py-2">
                            <div class="canvasChart">
                            <canvas [id]="cardsHeading[activeButton]['key'][index]" style="width: 100%"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            
        </div>
        <!-- slider images -->
        
        <!-- Score on Public Transport -->
        <!-- <div class="row" style="padding: 5px;">
            <div class="col-md-12 overallScoreCard">
                <div class="bg-white shadow mt-1">
                    <div class="chartHeader mt-3 d-flex align-items-center justify-content-between">
                        <h5>Score on Public Transport Facility near Golden Temple</h5>
                    </div>
                    <div class="card-body highlights-card-height py-2">
                        <div class="canvasChart">
                        <canvas [id]="id2"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<!-- Historical Page Render -->
<div *ngIf="showHistoricalPage">
    <app-historical-sites [siteAbout]="siteAbout" [scoreCardObj]="scoreCardObj" [allImageData]="allImageData"></app-historical-sites>
</div>
