import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
// import { CalendarComponent } from 'src/app/shared/calendar/calendar.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormBuilder, Validators } from '@angular/forms';
declare var window: any;
// import * as XLSX from 'xlsx';
import { HomeService } from 'src/app/shared/services/home.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MatSidenav } from '@angular/material/sidenav';
import { AgmMap, LatLngLiteral } from '@agm/core';
import * as L from "leaflet";
import { TileLayerOptions } from "leaflet";
import 'leaflet.markercluster';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit,AfterViewInit {
  @ViewChild(AgmMap) map: any;
  hidePageSize = true;
  stateBoundaries : any;
  isExpanded : boolean= false;
  extraList:any=[
    {
        "id": 123,
        "contentType": "image",
        "tickerName": null,
        "file_url": "../../../assets/banner/image2.jpeg",
        "ranking": 9,
        "createdBy": 1,
        "updatedBy": 1,
        "deletedBy": null,
        "isDeleted": "1",
        "createdAt": "2022-12-16T13:38:03.278+00:00",
        "updatedAt": "2022-12-16T13:38:03.278+00:00",
        "deletedAt": null
    },
    {
        "id": 123,
        "contentType": "image",
        "tickerName": null,
        "file_url": "../../../assets/banner/image3.jpeg",
        "ranking": 9,
        "createdBy": 1,
        "updatedBy": 1,
        "deletedBy": null,
        "isDeleted": "1",
        "createdAt": "2022-12-16T13:38:03.278+00:00",
        "updatedAt": "2022-12-16T13:38:03.278+00:00",
        "deletedAt": null
    },
    {
        "id": 123,
        "contentType": "image",
        "tickerName": null,
        "file_url": "../../../assets/banner/image4.jpeg",
        "ranking": 9,
        "createdBy": 1,
        "updatedBy": 1,
        "deletedBy": null,
        "isDeleted": "1",
        "createdAt": "2022-12-16T13:38:03.278+00:00",
        "updatedAt": "2022-12-16T13:38:03.278+00:00",
        "deletedAt": null
    },
    {
        "id": 123,
        "contentType": "image",
        "tickerName": null,
        "file_url": "../../../assets/banner/image5.jpeg",
        "ranking": 9,
        "createdBy": 1,
        "updatedBy": 1,
        "deletedBy": null,
        "isDeleted": "1",
        "createdAt": "2022-12-16T13:38:03.278+00:00",
        "updatedAt": "2022-12-16T13:38:03.278+00:00",
        "deletedAt": null
    },
    {
        "id": 123,
        "contentType": "image",
        "tickerName": null,
        "file_url": "../../../assets/banner/image6.jpeg",
        "ranking": 9,
        "createdBy": 1,
        "updatedBy": 1,
        "deletedBy": null,
        "isDeleted": "1",
        "createdAt": "2022-12-16T13:38:03.278+00:00",
        "updatedAt": "2022-12-16T13:38:03.278+00:00",
        "deletedAt": null
    }
  ];
  tickerString = '';
  carouselImages:any=[];
  popupDoc:any=[];
  mediaActive: boolean = true
  index:any='https://www.youtube.com/user/aajeevikaNRLM';

  lat: any;
  lng: any;

  markers: marker[] = [
	  {
		  lat: 28.679079,
		  lng: 77.069710,
		  label: 'A',
		  draggable: false
	  },
    {
		  lat: 28.979879,
		  lng: 77.969810,
		  label: 'AA',
		  draggable: false
	  },
	  {
		  lat: 26.850000,
		  lng: 77.949997,
		  label: 'B',
		  draggable: false
	  },
	  {
		  lat: 19.076090,
		  lng: 78.877426,
		  label: 'C',
		  draggable: true
	  },
	  {
		  lat: 18.076090,
		  lng: 74.877426,
		  label: 'C',
		  draggable: true
	  }
  ]
  countryRestriction = {
    latLngBounds: {
      east: 97.5,
      north: 10,
      south: 88.1,
      west: 68.7
    },
    strictBounds: true
    };
    
  showTable:boolean = false;  
  showMap:boolean = false;  
  hideIcon: boolean = false;
  isReadMore:boolean = false;
  isReadMoreDistrict:boolean = false;
  isReadMoreThematic:boolean = false;
  isReadMoreDestination:boolean = false;
  isReadMoreSiteName:boolean = false;
  showFilter:boolean = false;
  allFilterData:any[] = [];
  allStateData: any[] = [];
  destinationData: any[] = [];
  tableData:any [] = [];
  allDistrictData: any [] = [];
  allThemeticData: any [] = [];
  mapData: marker[] = [];
  checkboxState:any;
  dataLength: any[] = [];
  total: number = 0;
  currentPage = 0;
  resetZoom:number = 4.4
  pageSize = 10;
  filterData:any = {
    filterHead:[
      {name:"Overall Site Score", variable:"overallSiteRange", rangeValue:0}, 
      {name:"Accessibility", variable:"accessibilityRange", rangeValue:0}, 
      {name:"Amenities", variable:"amenitiesRange", rangeValue: 0}, 
      {name:"Hygiene", variable:"hygieneRange", rangeValue: 0}, 
      {name:"Safety & Security", variable: "safetySecurityRange", rangeValue: 0}
    ],
    scale:[0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1],
    minValue: 0,
    maxValue: 1,
    step: 0.1
}
  allSiteNameData: any[] = [];
  hideLocation:boolean =false;

  constructor(
    private router:Router,
    private dialog:MatDialog,
    private formbuilder: FormBuilder,
    private homeService: HomeService
    ) {
      let obj = ''
      this.overallScoreData(1,obj);
      this.overallScoreMap("");
      this.stateData();
      // this.siteNameData();
      // this.destinationAPIData();

     }
  
  siteName:string = "";

  filterObject:any = {
    siteName: [],
    state: [],
    destination: [],
    district: [],
    thematic: [],
    overallSiteRange: 0,
    accessibilityRange: 0,
    amenitiesRange: 0,
    hygieneRange: 0,
    safetySecurityRange: 0
  }

  updateTabContent = [
    // {
    //   title: "Vacancies in Deen Dayal Upadhyaya Grameen Kaushalya Yojana(DDU-GKY) (National Mission Manager – Skills / Mission Manager – Legal Affairs ). Last Date: 27th October 2021, 1700 hrs (IST).",
    //   desc: "Vacancies in Deen Dayal Upadhyaya Grameen Kaushalya Yojana(DDU-GKY) (National Mission Manager – Skills / Mission Manager – Legal Affairs ). Last Date: 27th October 2021, 1700 hrs (IST).",
    //   link:"https://aajeevika.gov.in/en/content/vacancies-deen-dayal-upadhyaya-grameen-kaushalya-yojanaddu-gky-national-mission-manager-%E2%80%93"
    // },

  ]
  lettersTabContent = [
  ]
  tendersTabContent = [
  ]
  eventsTabContent = [
  ]


  @ViewChild('sidenav') sidenav: MatSidenav | undefined;

  reason = '';

  close(reason: string) {
    this.reason = reason;
    this.sidenav?.close();
  }
  
  siteNameValue(event:any){
    console.log("siteEvent", event);
    
  }

  selectedTab = 'update';
  selectedTabData:any[] = [];
  Highlights:any[] = []
  homeModal: any;

  ngOnInit(): void {
    // this.getUpdates();
    // this.getHighlights();
    // const visitorJson = sessionStorage.getItem('nrlmVisitor');
    // const getvisitor = visitorJson !== null ? JSON.parse(visitorJson) : "";
    // if(!getvisitor){
    //   this.openWelcomeModal();
    // }
  //  this.getExtraList()
  // this.createMap();
  this.getCarouselList(this.extraList);
  this.filterData.scale = this.getSpteps(this.filterData.minValue,this.filterData.maxValue,this.filterData.step);
  this.homeService.getStatesGeoJson().then(res => {
    this.stateBoundaries = res.stateBoundaries;
    // this.districtBoundaries = res.districtBoundaries;
    this.overallScoreMap("");
  })
  }


  ngAfterViewInit(){
    // this.modal.open();
  }

  

  navigatiToPage(selectedTab:string){
    switch(selectedTab){
      case 'update':
        this.router.navigate(['/whats-new-section/updates']);
        break;
      case 'letters':
        this.router.navigate(['/whats-new-section/letters-circulars']);
        break;
      case 'events':
        // this.router.navigate(['/whats-new-section/events']);
        // this.openDialog()
        break;
      case 'tenders':
        this.router.navigate(['/whats-new-section/tenders']);
        break;
      case 'media':
      this.router.navigate(['whats-new-section/media-news']);
      break;
    }
  }

  openWelcomeModal(){
    this.homeModal = new window.bootstrap.Modal(
      document.getElementById('homeModal')
    );
    this.homeModal.show();

    sessionStorage.setItem('nrlmVisitor', JSON.stringify({
      'visited': true,
    }));
  }

 
  getTickerString(data:any){
    let tickerArray = data.filter((item:any)=>item.contentType=='ticker').sort((a:any,b:any)=>a.ranking-b.ranking).map((item:any)=>item.tickerName)
    if(!tickerArray.length) return;
    tickerArray.forEach((element:any,index:number) => {
      if(tickerArray.length==1){
        this.tickerString+=element;
      }else if(tickerArray.length>1 && tickerArray.length-1 != index ){
        this.tickerString+=element + ' | '
      }
      else {
        this.tickerString+=element
      }
    });
  }
  getCarouselList(data:any){
    this.carouselImages = data.filter((item:any)=>item.contentType=='image').sort((a:any,b:any)=>a.ranking-b.ranking).map((item:any)=>item.file_url)
    console.log(this.carouselImages,'dhagshd')

  }
  getPopup(data:any){
    console.log("Get Popup function call")
    this.popupDoc = [];
    this.popupDoc = data.filter((item:any)=>item.contentType=='popup');
    console.log("Popup doc ", this.popupDoc)
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(CalendarComponent,{
  //     data:{
  //       fileList:'',
  //     },
  //     width: '800px',
  //     height: '600px',
  //     panelClass: "foo"
  //   });
  //   dialogRef.afterClosed().subscribe((result:any) => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index
    this.navigateMedia()
  }
 
  navigateMedia(){
    if(this.index == 1){
      this.index = 'https://www.facebook.com/aajeevika' 
    }else if(this.index == 2){
      this.index = 'https://twitter.com/DAY_NRLM'
    } else{
      this.index = 'https://www.youtube.com/user/aajeevikaNRLM'
    }
  }

  clickedMarker(data:any) {
    sessionStorage.setItem('id',data.id);
    sessionStorage.setItem('site_name',data.site_name)
    this.router.navigate(
      ['/accessibility']
      // { queryParams: { id: data.site_id, site_name: data.site_name } }
    );
  }

  applyFilter(){
    // this.filterObject.siteName = this.siteName;
    console.log("newww",this.allDistrictData);
    console.log("this.filterObject", this.filterObject);
    let checkedSite = this.allSiteNameData.filter((item) => item.checked == true).map((data) => data.id).join(',');
    let checkedDistrict = this.allDistrictData.filter((item) => item.checked == true).map((data) => data.id).join(',');
    let checkedDestination = this.destinationData.filter((item) => item.checked == true).map((data) => data.id).join(',');
    let checkedTheme = this.allThemeticData.filter((item) => item.checked == true).map((data) => data.id).join(',');
    this.filterObject = {...this.filterObject, siteName: checkedSite ? checkedSite.split(',').filter((item:any) => item.trim()) : [], district: checkedDistrict ? checkedDistrict.split(',').filter((item:any) => item.trim()) : [],thematic: checkedTheme ? checkedTheme.split(',').filter((item:any) => item.trim()) : [],destination: checkedDestination ? checkedDestination.split(',').filter((item:any) => item.trim()) : [],};
    this.overallScoreData(1,this.filterObject);
    // window.location.reload();

     this.overallScoreMap(this.filterObject);

  }


  downloadTableCSV(){
   /* This will be opening a new window to download a CSV file
   using the URL returned by the `downloadCsvData()` method of the `homeService` object. The second
   parameter `'_blank'` specifies that the file should be opened in a new tab or window. */
   let appliedFilterData = this.filterObject;
    let url = this.homeService.downloadCsvData(appliedFilterData);
    window.open(url,'_blank');
  }

  overallScoreData(page:any, obj?:any){
    let limit = 10;
    this.showTable = true;
    this.homeService.getOverallScore(limit,page,obj).subscribe(response => {
      console.log("tabal data",response);
      if(response['success']){
        this.total = response?.total;
        this.tableData = response?.data;
        this.showTable = false;
        for(let data of this.tableData){
          this.dataLength = data?.id;
        }
      }
    },(error) => console.error(error));
  }

  siteNameData(stateID:any){
    this.homeService.getsiteNameData(stateID).subscribe(response => {
      console.log("allsitenames",response);
      if(response['success']){
        this.allSiteNameData = response?.data;
        this.allSiteNameData.map(el => el['checked'] = false);
      }
    },(error) => console.error(error));
    // this.stateData();
  }

  // Getting the State Data from the getState function using the homeService object
  stateData(){
    this.homeService.getState().subscribe(response => {
      console.log("stateResp",response);
      if(response['success']){
        this.allStateData = response.data;
        this.allStateData.map(el => el['checked'] = false);
      }
    },(error) => console.error(error));
  }

  destinationAPIData(stateId:any){
    this.homeService.getdestinationAPIData(stateId).subscribe(response => {
      console.log("destiii",response);
      if(response['success']){
        this.destinationData = response?.data;
        this.destinationData.map(el => el['checked'] = false);
      }
    },(error) => console.error(error));
  }


  isCheckboxTickedForSiteName(obj:any){
    console.log(obj);
    setTimeout(() => {
      let checkedSite = this.allSiteNameData.filter((item) => item.checked == true).map((data) => data.id).join(',')
    this.filterObject.siteName = checkedSite ? checkedSite.split(',').filter((item:any) => item.trim()) : [];
    },100)
    this.checkboxState = document.getElementById('site-'+obj?.id);
    console.log(this.checkboxState);
    // if(this.checkboxState.checked){
    //   this.filterObject.siteName.push(obj?.id);
    // }else{
    //   const dataIndex = this.filterObject.siteName.indexOf(obj?.id);
    //   this.filterObject.siteName.splice(dataIndex, 1);
    // }
    
  }

  isCheckboxTickedForDestination(obj:any){
    console.log(obj);
    setTimeout(() => {
      let checkedDestination = this.destinationData.filter((item) => item.checked == true).map((data) => data.id).join(',')
    this.filterObject.destination = checkedDestination ? checkedDestination.split(',').filter((item:any) => item.trim()) : [];
    },100)
    this.checkboxState = document.getElementById('dest-'+obj?.id);
    console.log(this.checkboxState);
    // if(this.checkboxState.checked){
    //   this.filterObject.destination.push(obj?.id);
    // }else{
    //   const dataIndex = this.filterObject.destination.indexOf(obj?.id);
    //   this.filterObject.destination.splice(dataIndex, 1);
    // }
  }

  // This function is used to check whether the checkbox is being ticked or not in the State Accordian.
  isCheckboxTicked(obj:any, index:any){
    this.checkboxState = document.getElementById('state-'+obj?.id);
    console.log(this.checkboxState, this.allStateData);
    setTimeout(() => {
      let checkedState:any = this.allStateData.filter((item) => item.checked == true).map((data) => data.id).join(',');
    if(checkedState){

    this.districtData(checkedState);
   
    }else{
      this.allDistrictData = [];
      this.allThemeticData = [];
      this.allSiteNameData=[];
      this.destinationData = [];
    }
   
    this.filterObject.state = checkedState ? checkedState.split(',').filter((item:any) => item.trim()) : [];
    console.log("ddd",this.allStateData, checkedState, this.filterObject)
    },100)
    
    // This cond. checks whether any of the checkbox is true of false.
    // if(this.checkboxState.checked){
    //   this.districtData(obj?.id);
    //   // Pushing the state IDs into the state array.
    //   this.filterObject.state.push(obj?.id);
    //   console.log(this.filterObject);
    // }else{
    //   // Getting the index of the selected state from the state ID.
    //   const dataIndex = this.filterObject.state.indexOf(obj?.id);
    //   // Removing that partipular state ID from the array using Splice with index parameter and count.
    //   this.filterObject.state.splice(dataIndex, 1);
    //     this.allDistrictData = this.allDistrictData.filter(data => {
    //       // Getting the index of the selected District from the district array of filterobject.
    //       const distIndex =  this.filterObject.district.indexOf(data.id);
    //       // Checking if the district Index is greater then the -1
    //       if(distIndex > -1){
    //         // Removing the selected district id from the array of the filterObject.district
    //         this.filterObject.district.splice(dataIndex, 1)
    //       }
    //       return obj.name != data?.state_name;

    //     });
    //     // this.allDistrictData = this.allDistrictData
    //     console.log("newww",this.allDistrictData);
        
        
    // }
  }

  // this function is being used to check whether the checkbox is checked from the Distric Accordian or not.
  isCheckboxTickedForDistrict(obj:any){

    setTimeout(() => {
      let checkedDistrict = this.allDistrictData.filter((item) => item.checked == true).map((data) => data.id).join(',')
    this.filterObject.district = checkedDistrict ? checkedDistrict.split(',').filter((item:any) => item.trim()) : [];
    },100)
    // Getting the specific ID of the selected District using getElementById default method.
    this.checkboxState = document.getElementById('district-'+obj?.id);
    // if(this.checkboxState.checked){
    //   this.filterObject.district.push(obj?.id);
    // }else{
    //   // If district checkbox is being unchecked then accoring to the index that needs to spliced from the original array.
    //   const dataIndex = this.filterObject.district.indexOf(obj?.id);
    //   this.filterObject.district.splice(dataIndex, 1);
    // }
  }

  // This function is being used for the Thematic Accordian to check any of the checkbox is being checked or not.
  isCheckboxTickedForThematic(obj:any){
    // Getting the specific ID of the selected Theme from the Thematic Accordian.
    setTimeout(() => {
      let checkedTheme = this.allThemeticData.filter((item) => item.checked == true).map((data) => data.id).join(',')
    this.filterObject.thematic = checkedTheme ? checkedTheme.split(',').filter((item:any) => item.trim()) : [];
    },100)
    this.checkboxState = document.getElementById('theme-'+obj?.id);
    // if(this.checkboxState.checked){
    //   // If the checkbox is checked, the ID is being pushed into the filterObject.thematic array.
    //   this.filterObject.thematic.push(obj?.id);  
    // }else{
    //   const dataIndex = this.filterObject.thematic.indexOf(obj?.id);
    //   this.filterObject.thematic.splice(dataIndex, 1);
    // }
  }

  districtData(stateID:any){
    this.siteNameData(stateID);
    this.themeticData(stateID);
    this.destinationAPIData(stateID);
    this.homeService.getDistrict(stateID).subscribe(response => {
      console.log("dist", response);
      if(response['success']){
        /* using the spread operator to concatenate the existing `allDistrictData`
        array with the `data` array from the `response` object. The resulting concatenated array is
        then assigned back to the `allDistrictData` property. */
        this.allDistrictData = response?.data;
        this.allDistrictData.map(el => el['checked'] = false);
      }
    },(error) => console.error(error));
  }

  themeticData(stateID:any){
    this.homeService.getThemeticData(stateID).subscribe(response => {
      if(response['success']){
        /* setting the value of `allThemeticData` to the `data` property of the `response` object,
        if it exists. Then, it is iterating over each element of `allThemeticData` using the `map`
        method and setting the value of the `checked` property of each element to `false`. */
        this.allThemeticData = response?.data;
        this.allThemeticData.map(el => el['checked'] = false);
      }
    })
  }

  overallScoreMap(obj:any){
    this.showMap = true;
    this.homeService.getOverallScoreMap(obj).subscribe(response => {
      console.log("mapReso",response);
      if(response['success']){
        this.showMap = false;
        this.mapData = response.data;
        this.mapData.map((item:any)=>{
          item.lat = Number(item.lat);
          item.lng = Number(item.lng);
        })
        this.makeMap();
      }
    })
  }

  
  makeMap() {
    
    //   let geoJson = this.stateBoundaries;
    //   var map : any= L.map('map', {
    //     zoomSnap: 0.25
    //   }).setView([22.43, 81.84], 4.1);
   
    //   var layer:any = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
    //     pane: 'myPane',
    //     maxZoom: 20,
    //     attribution: '&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //   }).addTo(map);
      
    //   var markers = L.markerClusterGroup({
    //     polygonOptions: {
    //       fillColor: 'white',
    //       color: 'white',
    //       weight: 0.5,
    //       opacity: 0.1,
    //       fillOpacity: 0.1
    //     }
    //   });
    //   L.geoJSON(geoJson).addTo(map);
    //   this.mapData.map((el: any) => {
    //     let arr: any = [el?.lat, el?.lng]
    //     var marker = markers.addLayer(L.marker(arr));
    //     console.log('markerdata==>', el)
    //     markers.on('click', (e) => {
    //       console.log('ererererer', e)
    //       this.clickedMarker(el);
  
    //     });
    //   })
    //   map.addLayer(markers);
    //   let onEachFeature = (feature:any, layer:any) => {
    //     var label = L.marker(layer.getBounds().getCenter(), {
    //         icon: L.divIcon({
    //             className: 'countryLabel',
    //             html: feature.properties.ST_NM,
    //             iconSize: [0, 0]
    //         })
    //     }).addTo(map);
    // };
    //   L.geoJSON(geoJson, {
    //     style: (feature) => {
    //       return {
    //         stroke: false,
    //         color: 'lightgray',
    //         weight: 1,
    //         opacity: 0.7,
    //         fill: true,
    //         fillColor: 'lightgray',
    //         fillOpacity: 0.1,
    //         smoothFactor: 0.5,
    //         interactive: false
    //       };
    //     },
    //     onEachFeature: onEachFeature,
    //   }).addTo(map);
    var container : any = L.DomUtil.get('map');
        if(container != null){
          container._leaflet_id = null;
        }
    var map:any = L.map("map",{zoomSnap: 0.25});
  
         map.createPane("top");
         map.getPane("top")!.style.zIndex = "380";
  
         map.createPane("middle");
         map.getPane("middle")!.style.zIndex = "360";
  
         map.createPane("bottom");
         map.getPane("bottom")!.style.zIndex = "340";
  
    var positron :any= L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
         maxZoom: 14,
        attribution: '.',
    }).addTo(map);
  
  var geojson : any= L.geoJson(this.stateBoundaries, { pane: "middle"}).addTo(map);
  
  var positronLabels = L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png",
    {
      pane: "top",
      attribution: ".",
      zIndex: 203
    }
  ).addTo(map);
  
      map.setView({ lat: 22.43, lng: 81.84 }, 4.4);
        var markers = L.markerClusterGroup({
        polygonOptions: {
          fillColor: 'white',
          color: 'white',
          weight: 0.5,
          opacity: 0.1,
          fillOpacity: 0.1
        }
      });
      this.mapData.map((el: any) => {
        let arr: any = [el?.lat, el?.lng]
        var marker = markers.addLayer(L.marker(arr).bindTooltip(el?.site_name, 
          {
              permanent: true, 
              direction: 'top',
              opacity: 0.75,
              className: 'leaflet-tooltip-own' 
          }
      ));
        console.log('markerdata==>', el)
        
        markers.on('click', (e:any) => {
          console.log('ererererer', e)
          if(el.lat == e.latlng.lat && el.lng == e.latlng.lng)
          this.clickedMarker(el);
  
        });
      })
      map.addLayer(markers);
    }

  createMap(){
    this.lat = 23.083656;
    this.lng = 82.797371;
  }
//   public centerChanged(coords: any) {
//     this.lat = 23;
//     this.lng = 82;
//   }

// public mapReady(map:any) {
//   map.addListener("dragend", () => {
//     this.centerChanged('')
//     this.lat = 23;
//     this.lng = 82;
//     this.resetZoom = 4.4
//     // do something with centerLatitude/centerLongitude
//     });
//   }
  resetFilter(){
    this.isExpanded = true
    setTimeout(() => {
      this.isExpanded = false
    }, 1);
    // window.location.reload();
    const initialBounds = { north: 10, south: 11, east: 12, west: 13 };
    // this.map.fitBounds = true
    // this.map.lng = 11
    // this.createMap();
console.log(this.allThemeticData);

    this.filterObject = {
      siteName: [],
      state: [],
      destination: [],
      district: [],
      thematic: [],
      overallSiteRange: 0,
      accessibilityRange: 0,
      amenitiesRange: 0,
      hygieneRange: 0,
      safetySecurityRange: 0

    };
    for(let allState of this.allStateData){
      if(allState.checked){
        allState.checked = false;
      }
    }
    this.allDistrictData = [];
    
    for(let allThematic of this.allThemeticData){
      if(allThematic.checked){
        allThematic.checked = false;
      }
    }
    for(let site of this.allSiteNameData){
      if(site.checked){
        site.checked = false;
      }
    }
    
    for(let destination of this.destinationData){
      if(destination.checked){
        destination.checked = false;
      }
    }
    this.overallScoreData(1,"");
    this.overallScoreMap("");
  }

  redirectRowData(el:any){
    console.log('redirectRowData(item', el);
    // let params = `id=${el.site_id}&site_name=${el.site_name}`;
    // let ids = atob(el.site_id)
    // let id = atob(el.site_id);
    // let site_name = atob(el.site_name);
    sessionStorage.setItem('id',el.id);
    sessionStorage.setItem('site_name',el.site_name)
    this.router.navigate(
      ['/accessibility']
      // { queryParams: { id: el.site_id, site_name: el.site_name } }
    );
  }

  pageChangeEvent(event: any){
    console.log(event);
    let page = event.pageIndex+1;
    this.currentPage = event.pageIndex;
    if(page){
      this.overallScoreData(page,this.filterObject);
    }

  }

  getSpteps(min:number,max:number,stepSize:number){
    let arr = [min.toString()];
    for(let i = 1; i < (max-min)/stepSize; i++){
      // console.log((0+(i*0.1)).toFixed(2));
      let num = (min+(i*stepSize)).toFixed(1);
      arr.push(num.toString())
    }
    arr.push(max.toString());
    return arr
  }

  // showText(){
  //   this.isReadMore = !this.isReadMore;
  // }

}
interface marker {
	lat: any;
	lng: any;
	label?: any;
	draggable: any;
}
