import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutUs = `About us`;
  aboutText = `India has a tradition of making its’ guests feel at home. However, the tourists often find the places visited by them lacking in Infrastructure and cleanliness. Special drives were also undertaken in past to sensitize the citizens about the cleanliness. Honourable Minister of Toursim had also suggested to develop a framework to assess and evaluate tourist sites in the country in the areas of cleaniless, hygiene, and public services etc.`;
  details = {
    d1: `The baseline survey was initaited to assess the sampled tourist destinations and their associated tourist sites in the country in parameters of infrastructure and cleanliness in order to get a comprehensive idea towards tourist destinations in terms of availability of amenities, quality of basic services, and maintenance of hygiene and identify any associated gaps.`,
    d2: `The assessment of tourist destinations and their associated tourist sites was done on two broad areas, namely, Infrastructure and Cleanliness. The areas chosen for the reasons as mentioned below:`,
    d3: `The tourist destination assessment framework included the following four key elements for evaluation:`
  };
  abcItems = [
    {
      item: `Accessibility: To capture the availability and quality of modes of accessibility of the tourist to the tourist site, evaluating aspects like transportation, accomodation among others.`
    },
    {
      item: `Amenities: To capture the availability and quality of the basic services and facilities at the tourist sites.`
    },
    {
      item: `Hygiene: To capture the level of cleanliness and hygiene at the tourist sites and its surronding areas and the availability and quality of measures to ensure due maintaneance of the same.`
    },
    {
      item: `Safety and security: To capture the availability and quality of measures at the toruist sites to ensure a safe and secure environment for the toruists.`
    }
  ]
  listItems = [
    {
      item: `They have a major impact on the overall tourist footfall and satisfaction.`,
    },
    {
      item: `These are areas in which the various stakeholders can actively intervene for continuous improvement.`
    }
  ];
  imageObject = [{
    image: "../../../assets/historical/download (1).jpg",
    thumbImage: "../../../assets/historical/download (1).jpg",
  }, {
    image: "../../../assets/historical/download (2).jpg",
    thumbImage: "../../../assets/historical/download (2).jpg",
  }, {
    image: "../../../assets/historical/download (3).jpg",
    thumbImage: "../../../assets/historical/download (3).jpg",
  }, {
    image: "../../../assets/historical/download (4).jpg",
    thumbImage: "../../../assets/historical/download (4).jpg",
  }, {
    image: "../../../assets/historical/download.jpg",
    thumbImage: "../../../assets/historical/download.jpg",
  }, {
    image: "../../../assets/historical/images (1).jpg",
    thumbImage: "../../../assets/historical/images (1).jpg",
  }, {
    image: "../../../assets/historical/images (2).jpg",
    thumbImage: "../../../assets/historical/images (2).jpg",
  }, {
    image: "../../../assets/historical/images.jpg",
    thumbImage: "../../../assets/historical/images.jpg",
  }];
  imageClick: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  
  imageClickHandler(e:any) {
    this.imageClick = true;
   }

   lbBox(e : any){
    this.imageClick = false;
   }
}
