import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
@NgModule({
    declarations: [
    
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule



    ],
    exports: [
        CommonModule,
        MatTooltipModule,
    ]
})
export class SharedModule { }
