<div class="container-fluid py-5" style="z-index: 1;position: relative;" [ngClass]="{'override-index' : imageClick}">
        <div class="row about-container-above">
            <div class="col-9">
                <div style="margin-top:10%;">
                    <p class="about-us text-center">{{aboutUs}}</p>
                    <p class="regular text-center">
                      {{aboutText}}
                    </p>
                </div>
            </div>
        </div>
        <div class="row px-4 mt-3">
            <div class="col-12">
                <p>{{details?.d1}}</p>              
            </div>
        </div>
        <div class="row" style="justify-content: center; display: none;">
            <div class="col-10 mt-5">
                <div style="text-align:center;margin:0; padding:0;">
                    <ng-image-slider #nav
                      [images]="imageObject"
                      [infinite]="false"
                      [autoSlide]="1"
                      [imageSize]="{width: '15%', height: 200,space: 15}"
                      slideImage="1" (imageClick)="imageClickHandler($event)" (lightboxClose)="lbBox($event)"></ng-image-slider>
                  </div>
            </div>
        </div>
    <div class="row px-4">
        <div class="col-12">
            <p>{{details?.d2}}</p>
           <ul style="margin-top:-10px">
            <li *ngFor="let i of listItems">
              {{i?.item}}
            </li>
           </ul>
           <p>{{details.d3}}</p>   
           <ol style="list-style-type: lower-alpha">
            <li *ngFor="let data of abcItems">
                {{data.item}}
            </li>
           </ol>
        </div>
        <!-- <div class="col-3 p-1">
               <img src="../../../assets/historical/The_Golden_Temple_of_Amrithsar_7@2x.png" alt="" class="img-fluid zoom">
        </div>
        <div class="col-3 p-1">
                <img src="../../../assets/historical/jallianwala_bagh@2x.png" alt="" class="img-fluid zoom">
        </div> -->
    </div>
</div>