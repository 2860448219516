import { Injectable } from "@angular/core";
import * as Chart from 'chart.js';


@Injectable({
    providedIn: 'root'
})
export class ChartService {

    arrayData:any = [];
    lastElement:any;
    graphData: any;
    

verticalBar(chartId:any,data:any,type:string) {
    this.arrayData = JSON.parse(JSON.stringify(data));
    console.log("dataCopy",this.arrayData.datasets);    
    /* iterating over the `datasets` array of the `arrayData` object and assigning the
    `data` property of each element to the `graphData` variable. It then logs the `data` property of
    each element to the console. */
    for(let a of this.arrayData.datasets){
        this.graphData = a.data;
        console.log("a.data",this.graphData);
    }
    /* `this.graphData.sort();` is sorting the `graphData` array in ascending order. This is useful for
    determining the maximum value of the y-axis in the chart, which is set to be the last element of
    the sorted array plus one. */
    this.graphData.sort();
    this.lastElement = this.graphData[this.graphData.length - 1];
    console.log("this.lastElement",this.lastElement);
    
    let dataCopy = JSON.parse(JSON.stringify(data));
    let ele:any = document.getElementById(chartId);
    if(!ele)
    return true;
    console.log("dataCopy",dataCopy);
    
    const myChart = new Chart(ele.getContext('2d'), {
        type: type || 'bar',
        data: dataCopy,
        options: {
            animation:{
                duration: 1,
                onComplete: function () {
                    let chartInstance:any = myChart;
                    var ctx:any = myChart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.fillStyle = 'rgba(0, 0, 0, 1)';
                    ctx.textBaseline = 'bottom';
                    data.datasets.forEach(function (dataset:any, i:any) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        const lineHeigt = ctx.measureText('M').width;
                        meta.data.forEach(function (bar:any, index:any) {
                            // console.log(bar);
                            var data = dataset.data[index];
                            let maxValue = Math.max(...dataset.data);
                            let percent = (data / maxValue) * 100;
                            console.log(dataset.data, maxValue, percent);
                            var data = dataset.data[index];
                            if (data > 0) {
                                ctx.translate(0, 0);
                                let y_pos = Math.round(bar._model.y - 5);
                                ctx.fillText(data, bar._model.x, y_pos);
                                console.log(y_pos);
                            }
                        });
                    });
                }
            },
            events: ['mousemove', 'mouseout'], // Listen to mousemove and mouseout events 
            onHover: (event, activeElements) => {
                 if (activeElements.length > 0 && myChart.options.plugins) { // If there are active elements (bars), hide the labels
                    if(myChart.options.plugins.labels)
                        myChart.options.plugins.labels.hidden = true; 
                    myChart.update(); 
                } else if(myChart.options.plugins) { // If there are no active elements, show the labels 
                    if(myChart.options.plugins.labels)
                        myChart.options.plugins.labels.hidden = false; 
                    myChart.update(); 
                } 
            },
            responsive: true,
            legend: {
                display: false
             },
            plugins:{
            title:{},
            },
            scales: {
                yAxes: [{
                    display: true,
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 1.0,
                        // max: this.lastElement + 1
                    }
                }]
            }
        }
    });
    return myChart
}
}