<!-- <div class="customNavbar bg-white py-1">
    <nav class="nav-ajvk bg-primary nav-primary">
        <div class="container-fluid">
            <div class="row px-3">
                <ul class="cust-navbar">
                    
                        <span style="opacity:0.7; color: white; font-size: 13px;">Site Name : </span>
                        <span style="color: white; font-size: 13px"><a (click)="redirectToAbout()" style="cursor:pointer;">
                            {{tName}}
                        </a></span>
                    
                    <li style="margin-left: 20px; ">
                        <a class="nav-link" (click)="accessibilityData(1)" [class.phase-active]="activeButton === 1">
                            Accessibility
                        </a>
                    </li>
                    <li  style="margin-left: 20px;">
                        <a class="nav-link" (click)="amenitiesData(2)" [class.phase-active]="activeButton === 2">
                            Amenities
                        </a>
                    </li>
                    <li  style="margin-left: 20px;">
                        <a class="nav-link" (click)="hygieneData(3)" [class.phase-active]="activeButton === 3">
                            Hygiene
                        </a>
                    </li>
                    <li  style="margin-left: 20px;">
                        <a class="nav-link" (click)="safetySecurityData(4)" [class.phase-active]="activeButton === 4">
                            Safety & Security
                        </a>
                    </li>
                </ul>    
            </div>
        </div>
    </nav>
    <div class="path">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex align-items-center p-3">
                    <span style="opacity:0.7" class="regular">Home / Historical /
                         <a (click)="redirectToAbout()" style="cursor:pointer;">
                            {{tName}} 
                         </a></span>
                    
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="container-fluid px-4 index" [ngClass]="{'override-index' : imageClick}">
    <!-- <div class="row" style="background-color: #F1F2FE">
        <div class="col-12 d-flex align-items-center p-2">
            <span style="opacity:0.7" class="regular">Home / Historical /</span>
            <span class="regular">{{tName}}</span>
        </div>
    </div> -->
    <!-- <div class="row">
        <div class="col-md-12">
            <i class="fa fa-cloud-download" aria-hidden="true" style="color:#0262af; cursor: pointer; font-size: 20px; float: right;" matTooltip="Download Images Zip" (click)="downloadZip()"></i>
        </div>
    </div> -->
    <i class="fa fa-cloud-download" aria-hidden="true" style="color:#0262af; cursor: pointer; font-size: 20px; float: right; margin-top: -56px;" matTooltip="Download" (click)="downloadZip()"></i>
    <div class="row mt-4">
        <div class="col-12">
           <div class="card p-2">
              <p class="about">{{titles[0]}}</p>
              <p>{{siteAbout ? siteAbout : "Dummy Data"}}</p>
              <!-- <p>{{aboutTextLess}}<span id="dots">...</span><span id="more">{{aboutTextMore}}</span></p>
                 <a (click)="seeMore()" id="myBtn" class="about">{{readMore}}</a> -->
           </div>
           
         
        </div>
        <!-- <div class="col-3 p-1">
               <img src="../../../assets/historical/The_Golden_Temple_of_Amrithsar_7@2x.png" alt="" class="img-fluid zoom">
               <img src="../../../assets/historical/800px-Ramtirath@2x.png" alt="" class="img-fluid mt-2 zoom">
        </div>
        <div class="col-2 p-1">
                <img src="../../../assets/historical/jallianwala_bagh@2x.png" alt="" class="img-fluid zoom">
                <img src="../../../assets/historical/Amritsar-feature-compressed@2x.png" alt="" class="img-fluid mt-2 zoom" style="height: 420px;">
        </div> -->
    </div>
    <div class="row" style="justify-content: center;">
        <div class="col-10 mt-5">
            <div style="text-align:center;">
                <ng-image-slider #nav
                  [images]="allImageData"
                  [infinite]="false"
                  [autoSlide]="1"
                  [imageSize]="{width: '15%', height: 200,space: 15}"
                  [slideImage]="2"
                  (imageClick)="imageClickHandler($event)" (lightboxClose)="lbBox($event)"
                  [showArrow]="allImageData.length < 6 ? false : true"
                  >
                </ng-image-slider>
              </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="card p-2 mt-3 mb-4">
                <p class="about">{{titles[1]}}</p>
                <div class="row d-flex mt-1">
                    <span class="col text-center card card-align m-3 p-2" *ngFor="let i of ratingCard" [matTooltip]="(scoreCardObj[i.percentScore]) | customPercentPipe" matTooltipPosition="above">
                        <img *ngIf="(scoreCardObj[i.percentScore])*100 >=66" src="../../../assets/historical/emoji-laugh-black-icon-1.svg" alt="" class="img-fluid hover-effect m-2" width="53" height="53">
                        <img *ngIf="(scoreCardObj[i.percentScore])*100 >= 34 && (scoreCardObj[i.percentScore])*100 < 66 " src="../../../assets/historical/confused-icon.svg" alt="" class="img-fluid hover-effect m-2" width="53" height="53">
                        <img *ngIf="(scoreCardObj[i.percentScore])*100 < 33 " src="../../../assets/historical/crying-black-icon.svg" alt="" class="img-fluid hover-effect m-2" width="53" height="53">
                        <!-- <img *ngIf="(scoreCardObj[i.percentScore])*100 >= 25 && (scoreCardObj[i.percentScore])*100 < 50 " src="../../../assets/historical/crying-black-icon.svg" alt="" class="img-fluid hover-effect m-2" width="53" height="53">
                        <img *ngIf="(scoreCardObj[i.percentScore])*100 < 25" src="../../../assets/historical/emoji-sad-black-icon.svg" alt="" class="img-fluid hover-effect m-2" width="53" height="53"> -->
                        <p class="text-center para-align">
                            {{i?.name}}
                        </p>
                        </span>
                </div>
                <div class="row d-flex m-2 text-center">
                    <div class="col-12 d-flex">
                        <div class="col-4" *ngFor="let i of ratingStatus">
                            <span class="m-2 text-center">
                                <img [src]="i?.path" alt="" class="img-fluid" width="20" height="20">
                                <span class="text-center">
                                    {{i?.name}}
                                </span>
                             </span>
                        </div>
                    </div>
                 </div>
                </div>
        </div>
    </div>
</div>