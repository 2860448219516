import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AccessibilityComponent } from './pages/accessibility/accessibility.component';
import { HistoricalSitesComponent } from './pages/historical-sites/historical-sites.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { DocumentsComponent } from './pages/documents/documents.component';


const routes: Routes = [

	{
		path: "home",
        component: HomeComponent,
	},
	{
		path: "",
		redirectTo:"home",
		pathMatch: 'full'
	},
  {
    path: "accessibility",
    component: AccessibilityComponent
  },
  {
    path: "historical-sites",
    component: HistoricalSitesComponent
  },
  {
    path: "about-us",
    component: AboutUsComponent
  },
  {
    path: "documents",
    component: DocumentsComponent
  },
  {
    path:'**',
    redirectTo:'/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
