import { Component, OnInit } from '@angular/core';
import { IframeDialogComponent } from './iframe-dialog/iframe-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  totalCount = 0;
  page = 1;
  pageSize = 1000;
  searchWord : string = '';
  cardData : any;
  clearButtonCheck : boolean = false;
  paginatorSize: any;
  isProgress: boolean = true;
  document:any = [{
    label: 'States',
    id:'state'
  },{
    label: 'Questionnaires',
    id:'Questionnaires'
  },{
    label: 'Concept Note',
    id:'Concept Note'
  }]
  searchTerm: any;
  isOpenFolder:boolean = false;
  searchFolder:string = '';

  //pagination
  pageEvent(event: any) {
    this.page = event;
    this.getDocumentList('',this.pageSize);
  }

  //search title of doc
  searchData(){
    // let searchKeyWord = this.searchWord;
    this.isOpenFolder = true;
    this.page = 1;
    this.isProgress = true;
    this.getDocumentList('',this.pageSize);
    // this.cardData = this.cardData.filter((obj:any) => JSON.stringify(obj.title).toLowerCase().includes(searchKeyWord.toString().toLowerCase()));
    // this.clearButtonCheck = true;
  }

  //clear data
  clearData(){
    this.isProgress = true;
    this.getDocumentList('',this.pageSize);
    this.clearButtonCheck = false;
  }

  constructor(private dialog:MatDialog, private contentService : HomeService ) { }

  ngOnInit(): void {
    this.isProgress = true;
    this.getDocumentList('',this.pageSize);
  }

  // get document list
  getDocumentList(params='',pageSize:any){
    this.contentService.getContent("document/list", `page=${this.page}&limit=${pageSize}&title=${this.searchWord}`).subscribe((res:any)=>{
      if(res){
        
        this.isProgress = false;
        console.log("res", res)
        this.cardData = res && res['data'].length && res['data'];
        if(this.searchWord)
        this.searchFolder = ['Questionnaires','Concept Note'].includes(this.cardData[0].state) ? this.cardData[0].state :'States';
        this.paginatorSize = this.searchWord ? res.total: 10;
        this.totalCount = res.total;
      }
    },error=>{
      console.log(error)
    })
  }

  //card open dialog
  openDialog(fileList:any) {
    let file = [{fileType: fileList?.type,fileUrl:fileList?.file_url, file: fileList?.state}]
    const dialogRef = this.dialog.open(IframeDialogComponent,{
      data:{
        fileList:file,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  //total downloads
  getTotalDownload(fileList:any){
    let total = 0;
    for (var i in fileList) {
      total += fileList[i].downloadCount;
    }
    return total

  }

  backToParent(){
    this.isOpenFolder = false;
  }

  openFolder(folder:any){
      this.isOpenFolder = true;
      this.searchFolder = folder.label;
      this.searchTerm = folder.id;
  }
}
