<div [ngClass]="{'blur-background': isLoading, '':!isLoading}">

<app-header></app-header>
  <router-outlet></router-outlet>
<app-footer></app-footer>
</div>

<div class="page-overlay-wrapper" *ngIf="isLoading">

  <div class="spinner">


      <div class="sk-circle">

          <div class="sk-circle1 sk-child"></div>

          <div class="sk-circle2 sk-child"></div>

          <div class="sk-circle3 sk-child"></div>

          <div class="sk-circle4 sk-child"></div>

          <div class="sk-circle5 sk-child"></div>

          <div class="sk-circle6 sk-child"></div>

          <div class="sk-circle7 sk-child"></div>

          <div class="sk-circle8 sk-child"></div>

          <div class="sk-circle9 sk-child"></div>

          <div class="sk-circle10 sk-child"></div>

          <div class="sk-circle11 sk-child"></div>

          <div class="sk-circle12 sk-child"></div>

        </div>


  </div>

</div>

<!-- <div id="loader" *ngIf="isLoading"></div> -->

